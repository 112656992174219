import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect, useMemo } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Dashboard from './dashboard';
import withAdminLayout from '../../../../layout/withAdminLayout';
import { useCurrentUser } from '../../../../model/queryCalls/queryCalls';

const Roles = lazy(() => import('../../../../container/roles'));
const AddRole = lazy(() => import('../../../../container/roles/addRoles'));
const Users = lazy(() => import('../../../../container/users/index'));
const AddUser = lazy(() => import('../../../../container/users/addUsers'));
const Posters = lazy(() => import('../../../../container/posters'));
const AddPoster = lazy(() => import('../../../../container/posters/addPosters'));
const ViewPoster = lazy(() => import('../../../../container/posters/viewPosters'));
const AddTrendingTag = lazy(() => import('../../../../container/trendingTags/addTrendingTags'));
const Podcasts = lazy(() => import('../../../../container/podcasts'));
const AddPodcast = lazy(() => import('../../../../container/podcasts/addPodcast'));
const ViewPodcast = lazy(() => import('../../../../container/podcasts/viewPodcast'));
const Shows = lazy(() => import('../../../../container/podcasts/allShows'));
const AddShow = lazy(() => import('../../../../container/podcasts/addShows'));
const ViewShow = lazy(() => import('../../../../container/podcasts/viewShows'));
const AllCms = lazy(() => import('../../../../container/cms'));
const AddNewCms = lazy(() => import('../../../../container/cms/addCms'));
const Settings = lazy(() => import('../../../../container/appSettings/index'));
const AppCustomers = lazy(() => import('../../../../container/appCustomers/index'));
const AddEvent = lazy(() => import('../../../../container/appSettings/addEvent'));
const Events = lazy(() => import('../../../../container/appSettings/events'));
const AppShows = lazy(() => import('../../../../container/appSettings/appShows'));
const AppMoreCategories = lazy(() => import('../../../../container/appSettings/appMoreCategories'));
const AppPersonalizedCategories = lazy(() => import('../../../../container/appSettings/appPersonalizedCategories'));
const PushNotificationsForNews = lazy(() => import('../../../../container/appSettings/pushNotificationsForNews'));

const Admin = React.memo(() => {
  const { data } = useCurrentUser();
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const routes = useMemo(() => {
    // const userPermissions = data?.permissions || [];
    // console.log('userPermissions:', userPermissions);
    const userRoutes = [
      { path: '/*', element: <Dashboard /> },
      { path: 'posters/*', element: <Posters /> },
      { path: 'posters/addPoster', element: <AddPoster /> },
      { path: 'posters/editPoster/:id', element: <AddPoster /> },
      { path: 'posters/viewPoster/:id', element: <ViewPoster /> },
      { path: 'trendingTags/addTrendingTag', element: <AddTrendingTag /> },
      { path: 'podcasts/*', element: <Podcasts /> },
      { path: 'podcasts/addPodcast', element: <AddPodcast /> },
      { path: 'podcasts/editPodcast/:id', element: <AddPodcast /> },
      { path: 'podcasts/viewPodcast/:id', element: <ViewPodcast /> },
      { path: 'shows/*', element: <Shows /> },
      { path: 'shows/addShows', element: <AddShow /> },
      { path: 'shows/editShows/:id', element: <AddShow /> },
      { path: 'shows/viewShows/:id', element: <ViewShow /> },
      { path: 'cms/*', element: <AllCms /> },
      { path: 'cms/addCms', element: <AddNewCms /> },
      { path: 'cms/editCms/:id', element: <AddNewCms /> },
      { path: 'settings/events', element: <Events /> },
      { path: 'settings/addEvent', element: <AddEvent /> },
      { path: 'settings/editEvent/:id', element: <AddEvent /> },
      { path: 'settings/appShows', element: <AppShows /> },
      { path: 'settings/appMoreCategories', element: <AppMoreCategories /> },
      { path: 'settings/appPersonalizedCategories', element: <AppPersonalizedCategories /> },
      { path: 'settings/pushNotificationsForNews', element: <PushNotificationsForNews /> },
      { path: 'users/*', element: <Users /> },
      { path: 'users/addNewUser', element: <AddUser /> },
      { path: 'users/editUser/:id', element: <AddUser /> },
      { path: 'roles/*', element: <Roles /> },
      { path: 'roles/addNewRole', element: <AddRole /> },
      { path: 'roles/editRole/:id', element: <AddRole /> },
      { path: 'appCustomers/*', element: <AppCustomers /> },
      { path: 'settings/*', element: <Settings /> },
    ];

    // Example conditional checks
    // if (userPermissions.includes('SuperUser')) {
    //   userRoutes.push(
    //     { path: 'users/*', element: <Users /> },
    //     { path: 'users/addNewUser', element: <AddUser /> },
    //     { path: 'users/editUser/:id', element: <AddUser /> },
    //     { path: 'roles/*', element: <Roles /> },
    //     { path: 'roles/addNewRole', element: <AddRole /> },
    //     { path: 'roles/editRole/:id', element: <AddRole /> },
    //     { path: 'appCustomers/*', element: <AppCustomers /> },
    //     { path: 'settings/*', element: <Settings /> },
    //   );
    //   // Add more routes that require SuperUser permission
    // }

    return userRoutes;
  }, [data?.permissions]);
  return (
    <Suspense
      fallback={
        <div className="spin flex items-center justify-center bg-white dark:bg-dark h-screen w-full fixed z-[999] ltr:left-0 rtl:right-0 top-0">
          <Spin />
        </div>
      }
    >
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
        {/* <Route index path="/*" element={<Dashboard />} />
        <Route path="users/*" element={<Users />} />
        <Route path="users/addNewUser" element={<AddUser />} />
        <Route path="users/editUser/:id" element={<AddUser />} />
        <Route path="roles/*" element={<Roles />} />
        <Route path="roles/addNewRole" element={<AddRole />} />
        <Route path="roles/editRole/:id" element={<AddRole />} />
        <Route path="posters/*" element={<Posters />} />
        <Route path="posters/addPoster" element={<AddPoster />} />
        <Route path="posters/editPoster/:id" element={<AddPoster />} />
        <Route path="posters/viewPoster/:id" element={<ViewPoster />} />
        <Route path="trendingTags/addTrendingTag" element={<AddTrendingTag />} />
        <Route path="podcasts/*" element={<Podcasts />} />
        <Route path="podcasts/addPodcast" element={<AddPodcast />} />
        <Route path="podcasts/editPodcast/:id" element={<AddPodcast />} />
        <Route path="podcasts/viewPodcast/:id" element={<ViewPodcast />} />
        <Route path="shows/*" element={<Shows />} />
        <Route path="shows/addShows" element={<AddShow />} />
        <Route path="shows/editShows/:id" element={<AddShow />} />
        <Route path="shows/viewShows/:id" element={<ViewShow />} />
        <Route path="cms/*" element={<AllCms />} />
        <Route path="cms/addCms" element={<AddNewCms />} />
        <Route path="cms/editCms/:id" element={<AddNewCms />} />
        <Route path="settings/*" element={<Settings />} />
        <Route path="settings/events" element={<Events />} />
        <Route path="settings/addEvent" element={<AddEvent />} />
        <Route path="settings/editEvent/:id" element={<AddEvent />} />
        <Route path="settings/appShows" element={<AppShows />} />
        <Route path="settings/appMoreCategories" element={<AppMoreCategories />} />
        <Route path="settings/appPersonalizedCategories" element={<AppPersonalizedCategories />} />
        <Route path="settings/pushNotificationsForNews" element={<PushNotificationsForNews />} />
        <Route path="appCustomers/*" element={<AppCustomers />} /> */}
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);
